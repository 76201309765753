<template>
  <div class="page page--customers customers" id="customers">
    <h2>{{ $t('customers.title') }}</h2>
    <div class="customers__controls">
      <button class="button" :class="{'button--disabled':currentPage===1}" @click="showMore(-1)">{{ $t('previous') }}
      </button>
      <button class="button" :class="{'button--disabled':currentPage===maxPage}" @click="showMore(1)">{{ $t('next') }}
      </button>
    </div>
    <div class="customers__container" v-if="hasFeedback">
      <div class="customers__feedback" v-for="(item,index) in feedback" :key="index">
        <div class="customers__feedback__stars">
          <fa-icon class="customers__feedback__stars__star customers__feedback__stars__star--active"
                   v-for="star in parseInt(item.SCORE)" :key="`active-${star}-${index}`" icon="star"></fa-icon>
          <fa-icon class="customers__feedback__stars__star customers__feedback__stars__star--inactive"
                   v-for="star in (4 - parseInt(item.SCORE))" :key="`inactive-${star}-${index}`" icon="star"></fa-icon>
        </div>
        <p v-if="item.FEEDBACK" class="customers__feedback__text"><q>{{ item.FEEDBACK }}</q></p>
        <p>{{ `${item.DATUM} - ${item.VOORNAAM}${item.NAAM}, ${item.STAD}` }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Customers",

  data() {
    return {
      currentPage: 1,
      pageSize: 10
    }
  },

  created() {
    this.getFeedback();
  },

  mounted() {
    window.scrollTo(0, 0);
  },

  computed: {
    token(){
      return this.$store.getters.token;
    },
    feedback(){
      return this.$store.getters['feedback/feedback'];
    },
    hasFeedback(){
      return this.$store.getters['feedback/hasFeedback'];
    },
    maxPage(){
      return this.$store.getters['feedback/feedbackPages'];
    }
  },

  watch: {
    token(){
      if(this.token && !this.hasFeedback){
        this.getFeedback();
      }
    }
  },

  methods: {
    async getFeedback() {
      if(!this.token){
        return;
      }
      try{
        await this.$store.dispatch('feedback/getFeedback',{PageNumber:this.currentPage,PageSize: this.pageSize});
      } catch(e){
        console.warn(e);
      }
    },

    showMore(value) {
      this.currentPage = this.currentPage + value;
      this.getFeedback();
    }
  }
}
</script>